.gallery-card1-gallery-card {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  border-color: var(--dl-color-gray-black);
  border-width: 0px;
  flex-direction: column;
  justify-content: center;
}
.gallery-card1-image {
  flex: 1;
  width: 100%;
  height: 100%;
  object-fit: cover;
}


@media(max-width: 767px) {
  .gallery-card1-gallery-card {
    flex-direction: column;
  }
}
@media(max-width: 479px) {
  .gallery-card1-image {
    height: 10rem;
  }
}
