.home-container {
  width: 100%;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  align-items: center;
  flex-direction: column;
  scroll-behavior: smooth;
}
.home-modal {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  z-index: 200;
  position: fixed;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  backdrop-filter: blur(4px);
  justify-content: center;
}
.home-practices {
  display: flex;
  padding: var(--dl-space-space-oneandhalfunits);
  box-shadow: 0px 0px 25px -2px rgba(66, 68, 90, 0.4);
  align-items: center;
  border-radius: 12px;
  flex-direction: column;
  justify-content: center;
  background-color: #ffffff;
}
.home-heading {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-header {
  font-size: 32px;
  font-style: normal;
  font-weight: 600;
}
.home-close {
  width: 24px;
  cursor: pointer;
  height: 24px;
  transition: 0.3s;
}
.home-close:hover {
  opacity: 0.5;
}
.home-grid {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
.home-section {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading01 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header01 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption {
  max-width: 385px;
  line-height: 24px;
}
.home-text {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image {
  width: 12px;
  object-fit: cover;
}
.home-section1 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading02 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header02 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption1 {
  max-width: 385px;
  line-height: 24px;
}
.home-text01 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image01 {
  width: 12px;
  object-fit: cover;
}
.home-section2 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading03 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header03 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption2 {
  max-width: 385px;
  line-height: 24px;
}
.home-text02 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image02 {
  width: 12px;
  object-fit: cover;
}
.home-section3 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading04 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header04 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption3 {
  max-width: 385px;
  line-height: 24px;
}
.home-text03 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image03 {
  width: 12px;
  object-fit: cover;
}
.home-section4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading05 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header05 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption4 {
  max-width: 385px;
  line-height: 24px;
}
.home-text04 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image04 {
  width: 12px;
  object-fit: cover;
}
.home-section5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-threeunits);
  border-color: #B4B9C6;
  flex-direction: column;
  border-top-width: 1px;
}
.home-heading06 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header06 {
  font-size: 30px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}
.home-caption5 {
  max-width: 385px;
  line-height: 24px;
}
.home-text05 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image05 {
  width: 12px;
  object-fit: cover;
}
.home-hero {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
}
.home-navbar {
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: center;
  padding-top: var(--dl-space-space-oneandhalfunits);
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: space-between;
}
.home-left {
  gap: var(--dl-space-space-twounits);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-logo {
  width: 165px;
  object-fit: cover;
}
.home-links {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-link {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-link:hover {
  opacity: 0.5;
}
.home-link01 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-link01:hover {
  opacity: 0.5;
}
.home-link02 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
}
.home-link02:hover {
  opacity: 0.5;
}
.home-link03 {
  cursor: pointer;
  font-style: normal;
  transition: 0.3s;
  font-weight: 500;
  line-height: 24px;
  text-decoration: none;
}
.home-link03:hover {
  opacity: 0.5;
}
.home-right {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-phone {
  gap: var(--dl-space-space-halfunit);
  cursor: pointer;
  display: flex;
  align-items: center;
  border-width: 0px;
  flex-direction: row;
  justify-content: center;
}
.home-image06 {
  width: 18px;
  object-fit: cover;
}
.home-text06 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-book {
  text-decoration: none;
}
.home-image07 {
  width: 18px;
  object-fit: cover;
}
.home-text07 {
  font-family: Inter;
}
.home-burger-menu {
  display: none;
  align-items: center;
  justify-content: center;
}
.home-icon1 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-mobile-menu {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  display: none;
  padding: 32px;
  z-index: 1000;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  background-color: #fff;
}
.home-nav {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-container1 {
  width: 100%;
  display: flex;
  align-items: center;
  margin-bottom: var(--dl-space-space-threeunits);
  justify-content: space-between;
}
.home-image08 {
  height: 2rem;
}
.home-menu-close {
  display: flex;
  align-items: center;
  justify-content: center;
}
.home-icon3 {
  width: var(--dl-size-size-xsmall);
  height: var(--dl-size-size-xsmall);
}
.home-nav1 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text08 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text09 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text10 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-text11 {
  margin-bottom: var(--dl-space-space-unit);
}
.home-book1 {
  text-decoration: none;
}
.home-image09 {
  width: 18px;
  object-fit: cover;
}
.home-text12 {
  font-family: Inter;
}
.home-main {
  flex: 1;
  width: 100%;
  display: flex;
  z-index: 100;
  max-width: 1440px;
  align-items: stretch;
  padding-left: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: space-between;
}
.home-content {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-top: var(--dl-space-space-oneandhalfunits);
  flex-direction: column;
  padding-bottom: var(--dl-space-space-oneandhalfunits);
  justify-content: center;
}
.home-heading07 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: center;
}
.home-header07 {
  width: 100%;
  font-size: 62px;
  max-width: 750px;
  font-style: normal;
  font-weight: 600;
  line-height: 74px;
}
.home-caption6 {
  width: 100%;
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-image10 {
  width: 18px;
  object-fit: cover;
}
.home-image11 {
  flex: 1;
  display: flex;
  align-items: flex-end;
  padding: var(--dl-space-space-fiveunits);
  flex-direction: row;
  justify-content: center;
}
.home-image12 {
  max-height: 500px;
  height: 95%;
  object-fit: cover;
}
.home-features {
  width: 100%;
  display: flex;
  z-index: 100;
  align-items: center;
  padding-top: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: center;
  background-color: #2461FF;
}
.home-content1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
}
.home-background {
  right: 0px;
  width: 37%;
  bottom: 0px;
  height: 100%;
  display: flex;
  position: absolute;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-end;
  background-color: #D0F4FD;
}
.home-practices1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: flex-start;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: 120px;
  flex-direction: row;
  padding-bottom: 120px;
}
.home-heading08 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-text19 {
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
}
.home-text20 {
  font-size: 18px;
  max-width: 385px;
  line-height: 27px;
}
.home-content2 {
  gap: var(--dl-space-space-fourunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-grid1 {
  gap: var(--dl-space-space-threeunits);
  flex: 1;
  display: grid;
  align-items: flex-start;
  flex-direction: column;
  grid-template-columns: repeat(2, 1fr);
}
.home-practice-wrapper {
  cursor: pointer;
  text-decoration: none;
}
.home-practice-wrapper1 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice-wrapper2 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice-wrapper3 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice-wrapper4 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice-wrapper5 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice-wrapper6 {
  cursor: pointer;
  text-decoration: none;
}
.home-practice-wrapper7 {
  cursor: pointer;
  text-decoration: none;
}
.home-why {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  background-color: #D0F4FD;
}
.home-heading09 {
  gap: var(--dl-space-space-unit);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-header08 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-header09 {
  font-size: 18px;
  max-width: 700px;
  text-align: center;
  line-height: 27px;
}
.home-container2 {
  flex: 0 0 auto;
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
}
.home-gallery {
  flex: 1;
  width: 100%;
  display: grid;
  grid-gap: var(--dl-space-space-unit);
  max-width: var(--dl-size-size-maxwidth);
  align-self: center;
  grid-template-columns: 1fr 1fr ;
}
.home-content3 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-video {
  width: 100%;
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  position: relative;
  max-width: 900px;
  align-items: center;
  flex-direction: column;
}
.home-caption7 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-header10 {
  font-size: 24px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
  line-height: 36px;
}
.home-header11 {
  max-width: 600px;
  text-align: center;
  line-height: 24px;
}
.home-features1 {
  gap: 120px;
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
}
.home-section6 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content4 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header12 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading10 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.home-capton {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-text21 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image13 {
  width: 12px;
  object-fit: cover;
}
.home-image14 {

  object-fit: cover;
}
.home-section7 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row-reverse;
  justify-content: space-between;
}
.home-content5 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header13 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading11 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.home-capton1 {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-text22 {
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}
.home-image15 {
  width: 12px;
  object-fit: cover;
}
.home-image16 {

  text-align: center;
  max-width: 600px;
  object-fit: cover;
}
.home-section8 {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}
.home-content6 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-header14 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-heading12 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  font-weight: 600;
}
.home-capton2 {
  font-size: 18px;
  max-width: 600px;
  line-height: 27px;
}
.home-image17 {
  width: 580px;
  object-fit: cover;
}
.home-book4 {
  display: none;
}
.home-schedule {
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
}
.home-content7 {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-fiveunits);
  align-items: center;
  flex-direction: column;
  background-color: #2461FF;
}
.home-header15 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-heading13 {
  color: #ffffff;
  font-size: 56px;
  max-width: 800px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-caption8 {
  color: rgba(255, 255, 255, 0.8);
  font-size: 18px;
  max-width: 900px;
  text-align: center;
  line-height: 27px;
}
.home-types {
  gap: var(--dl-space-space-unit);
  border: 2px dashed rgba(120, 120, 120, 0.4);
  display: flex;
  align-items: center;
  flex-direction: row;
}
.home-book5 {
  gap: var(--dl-space-space-fiveunits);
  width: 100%;
  display: flex;
  max-width: 1440px;
  align-items: center;
  padding-top: 120px;
  border-color: #B4B9C6;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: column;
  padding-bottom: 120px;
  justify-content: center;
  border-bottom-width: 1px;
}
.home-heading14 {
  gap: var(--dl-space-space-oneandhalfunits);
  display: flex;
  align-items: center;
  flex-direction: column;
}
.home-text25 {
  font-size: 56px;
  max-width: 600px;
  font-style: normal;
  text-align: center;
  font-weight: 600;
}
.home-text26 {
  font-size: 18px;
  max-width: 700px;
  text-align: center;
  line-height: 27px;
}
.home-form {
  gap: var(--dl-space-space-fourunits);
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-types1 {
  gap: var(--dl-space-space-unit);
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
}
.home-text27 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-text28 {
  font-style: normal;
  font-family: Inter;
  font-weight: 600;
  white-space: nowrap;
}
.home-inputs {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  max-width: 600px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.home-date {
  width: 100%;
  display: flex;
  align-items: flex-end;
  flex-direction: row;
  justify-content: center;
}
.home-image18 {
  width: 20px;
  object-fit: cover;
  border-color: #B4B9C6;
  padding-bottom: var(--dl-space-space-unit);
  border-bottom-width: 1px;
}
.home-lower {
  gap: var(--dl-space-space-twounits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-text29 {
  font-size: 10px;
  max-width: 700px;
  text-align: left;
  line-height: 15px;
}
.home-button {
  gap: var(--dl-space-space-oneandhalfunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.home-book6 {
  width: 100%;
}
.home-text31 {
  font-size: 10px;
  max-width: 700px;
  text-align: left;
  line-height: 15px;
}
.home-text33 {
  color: #2461ff;
  font-style: normal;
  font-weight: 600;
}
.home-text35 {
  color: #2461ff;
  font-style: normal;
  font-weight: 600;
}
.home-footer {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 120px;
  padding-left: var(--dl-space-space-fiveunits);
  padding-right: var(--dl-space-space-fiveunits);
  flex-direction: row;
  padding-bottom: var(--dl-space-space-fiveunits);
  justify-content: space-between;
  background-color: #000000;
}
.home-left1 {
  gap: var(--dl-space-space-threeunits);
  display: flex;
  align-items: flex-start;
  padding-right: var(--dl-space-space-threeunits);
  flex-direction: column;
}
.home-brand {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: column;
}
.home-image19 {
  width: 165px;
  object-fit: cover;
}
.home-text37 {
  color: rgb(255, 255, 255);
  font-size: 14px;
  line-height: 21px;
}
.home-socials {
  gap: var(--dl-space-space-unit);
  display: flex;
  max-width: 400px;
  align-items: flex-start;
  flex-direction: row;
}
.home-image20 {
  width: 24px;
  object-fit: cover;
}
.home-image21 {
  width: 24px;
  object-fit: cover;
}
.home-image22 {
  width: 24px;
  object-fit: cover;
}
.home-legal {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-copyright {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.home-right1 {
  gap: 140px;
  display: flex;
  align-items: flex-start;
  flex-direction: row;
}
.home-list {
  gap: var(--dl-space-space-unit);
  flex: 1;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header16 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.home-links1 {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link04 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link04:hover {
  opacity: 0.5;
}
.home-link05 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link05:hover {
  opacity: 0.5;
}
.home-link06 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link06:hover {
  opacity: 0.5;
}
.home-link07 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link07:hover {
  opacity: 0.5;
}
.home-link08 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link08:hover {
  opacity: 0.5;
}
.home-list1 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header17 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.home-links2 {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link09 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link09:hover {
  opacity: 0.5;
}
.home-link10 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link10:hover {
  opacity: 0.5;
}
.home-link11 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link11:hover {
  opacity: 0.5;
}
.home-link12 {
  color: rgb(255, 255, 255);
  cursor: pointer;
  transition: 0.3s;
}
.home-link12:hover {
  opacity: 0.5;
}
.home-list2 {
  gap: var(--dl-space-space-unit);
  flex: 1;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-header18 {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 600;
}
.home-links3 {
  gap: 12px;
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.home-link13 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
}
.home-link13:hover {
  opacity: 0.5;
}
.home-link14 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.home-link14:hover {
  opacity: 0.5;
}
.home-link15 {
  color: rgb(255, 255, 255);
  width: 215px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
}
.home-link15:hover {
  opacity: 0.5;
}
.home-legal1 {
  gap: var(--dl-space-space-threeunits);
  width: 100%;
  display: none;
  align-items: flex-start;
  flex-direction: row;
}
.home-row {
  display: flex;
}
.home-copyright5 {
  color: rgb(255, 255, 255);
  font-size: 12px;
}
.home-container4 {
  display: contents;
}
.home-container6 {
  display: contents;
}
.home-container8 {
  display: contents;
}
@media(max-width: 991px) {
  .home-hero {
    flex: 1;
    height: 0px;
  }
  .home-navbar {
    z-index: 1000;
  }

  .home-right {
    display: none;
  }
  .home-burger-menu {
    display: flex;
    padding: var(--dl-space-space-unit);
    border-radius: var(--dl-radius-radius-round);
    background-color: #2461FF;
  }
  .home-icon1 {
    fill: white;
  }
  .home-main {
    flex-direction: column;
  }
  .home-content {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .home-header07 {
    max-width: 75%;
  }
  .home-caption6 {
    max-width: 75%;
  }
  .home-image11 {
    flex: 0 0 auto;
    align-items: flex-end;
    padding-right: var(--dl-space-space-twounits);
    justify-content: flex-end;
  }
  .home-features {
    padding-top: var(--dl-space-space-fourunits);
    flex-direction: row;
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content1 {
    flex-direction: column;
  }
  .home-background {
    left: 0px;
    width: 100%;
    bottom: 0px;
    height: 55%;
    position: absolute;
    justify-content: flex-end;
  }
  .home-practices1 {
    flex-direction: column;
  }
  .home-why {
    gap: var(--dl-space-space-threeunits);
  }
  .home-gallery {
    grid-template-columns: 1fr 1fr;
  }
  .home-features1 {
    gap: var(--dl-space-space-threeunits);
  }
  .home-section6 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image14 {
    width: 100%;
  }
  .home-section7 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image16 {
    margin: auto;
    width: 100%;
  }
  .home-section8 {
    gap: var(--dl-space-space-twounits);
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-image17 {
    width: 100%;
  }
  .home-schedule {
    padding-left: 0px;
    padding-right: 0px;
  }
  .home-types {
    width: 100%;
    flex-direction: column;
  }
  .home-footer {
    gap: 100px;
    align-items: flex-start;
    flex-direction: column;
    justify-content: flex-start;
  }
  .home-left1 {
    padding-right: 0px;
  }
  .home-socials {
    display: none;
  }
  .home-legal {
    display: none;
  }
  .home-right1 {
    gap: var(--dl-space-space-twounits);
    flex-direction: column;
  }
  .home-legal1 {
    gap: var(--dl-space-space-twounits);
    display: flex;
    flex-direction: column;
  }
  .home-row {
    gap: var(--dl-space-space-oneandhalfunits);
  }
}
@media(max-width: 767px) {
  .home-navbar {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-unit);
  }
  .home-burger-menu {
    display: flex;
  }
  .home-main {
    gap: var(--dl-space-space-threeunits);
    align-items: flex-end;
    padding-left: var(--dl-space-space-oneandhalfunits);
    justify-content: center;
  }
  .home-header07 {
    font-size: 32px;
    max-width: 70%;
    line-height: 38px;
  }
  .home-caption6 {
    font-size: 16px;
    max-width: 70%;
    line-height: 24px;
  }
  .home-content1 {
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-practices1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading08 {
    gap: var(--dl-space-space-unit);
  }
  .home-text19 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-text20 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-content2 {
    gap: var(--dl-space-space-threeunits);
    width: 100%;
  }
  .home-grid1 {
    gap: var(--dl-space-space-twounits);
    width: 100%;
    grid-template-columns: repeat(1, 1fr);
  }
  .home-why {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-header08 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-header09 {
    font-size: 14px;
    max-width: 60%;
    line-height: 21px;
  }
  .home-gallery {
    padding-left: var(--dl-space-space-twounits);
    padding-right: var(--dl-space-space-twounits);
  }
  .home-content3 {
    gap: var(--dl-space-space-twounits);
  }
  .home-header10 {
    font-size: 22px;
    line-height: 33px;
  }
  .home-header11 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-features1 {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content4 {
    gap: var(--dl-space-space-unit);
  }
  .home-heading10 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-heading11 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton1 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-heading12 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-capton2 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-book3 {
    display: none;
  }
  .home-book4 {
    width: 100%;
    display: flex;
  }
  .home-schedule {
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-content7 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading13 {
    font-size: 30px;
    line-height: 36px;
  }
  .home-caption8 {
    font-size: 14px;
    line-height: 21px;
  }
  .home-types {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-book5 {
    gap: var(--dl-space-space-twounits);
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-fourunits);
  }
  .home-heading14 {
    gap: var(--dl-space-space-unit);
  }
  .home-text25 {
    font-size: 24px;
  }
  .home-text26 {
    font-size: 14px;
    max-width: 80%;
    line-height: 21px;
  }
  .home-form {
    gap: var(--dl-space-space-threeunits);
  }
  .home-types1 {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-inputs {
    gap: var(--dl-space-space-oneandhalfunits);
  }
  .home-footer {
    padding-top: var(--dl-space-space-fourunits);
    padding-left: var(--dl-space-space-oneandhalfunits);
    padding-right: var(--dl-space-space-oneandhalfunits);
    padding-bottom: var(--dl-space-space-threeunits);
  }
  .home-image19 {
    width: 110px;
  }
  .home-text37 {
    font-size: 12px;
    line-height: 18px;
  }
}
@media(max-width: 490px) {
  .home-logo{
    width: 100px;
  }
}
@media(max-width: 410px) {
  .home-logo{
    width: 80px;
  }
}

  .home-image12 {
    max-width: 100vw
  }
@media(max-width: 479px) {
  .home-navbar {
    padding: var(--dl-space-space-unit);
  }
  .home-mobile-menu {
    padding: 16px;
  }
  .home-content {
    padding-right: var(--dl-space-space-oneandhalfunits);
  }
  .home-header07 {
    max-width: 80%;
  }
  .home-caption6 {
    max-width: 100%;
  }
  .home-book2 {
    width: 100%;
  }
  .home-image11 {

    display: flex;
    align-items: center;
    align-content: center;

  }
  .home-image12 {
    max-width: 100vw;
  }
  .home-gallery {
    padding-top: var(--dl-space-space-twounits);
    padding-left: var(--dl-space-space-unit);
    padding-right: var(--dl-space-space-unit);
    padding-bottom: var(--dl-space-space-twounits);
  }
  .home-types1 {
    flex-direction: column;
  }
}

